import ROUTES from './routes'

export const BLOCKED_VIEWS = {
  only_checkout: [
    ROUTES.WAREHOUSES,
    ROUTES.PACKAGES,
    ROUTES.SALES,
    '/',
    ROUTES.DASHBOARD,
    ROUTES.MANIFEST
  ],
  full: []
}

export const USER_PERMISSIONS_VIEWS = [
  { name: 'dashboard', label: 'Dashboard' },
  { name: 'sales', label: 'Ventas' },
  { name: 'incidents', label: 'Incidencias' },
  { name: 'manifest', label: 'Manifiesto' },
  { name: 'warehouses', label: 'Puntos' },
  { name: 'personalization', label: 'Personalización' },
  { name: 'pickup-app', label: 'Pickup App' },
  { name: 'multivende', label: 'Multivende' },
  { name: 'couriers', label: 'Couriers' },
  { name: 'tracking', label: 'Trazabilidad' },
  { name: 'prices', label: 'Precios' },
  { name: 'rules', label: 'Reglas' },
  { name: 'tickets', label: 'Solicitudes' },
  { name: 'distribution', label: 'Distribución' }
]

export const DEACTIVATED_USERS = [
  'info@mundopetit.cl',
  'admin@grupogaillard.cl'
]
